let width = document.documentElement.clientWidth || window.innerWidth;
const BREAK_POINT = 900;
let h1Size = getComputedStyle(document.documentElement).getPropertyValue(
	'--h1-size'
);
let h2Size = getComputedStyle(document.documentElement).getPropertyValue(
	'--h2-size'
);
let h3Size = getComputedStyle(document.documentElement).getPropertyValue(
	'--h3-size'
);
let bodySize = getComputedStyle(document.documentElement).getPropertyValue(
	'--body-size'
);
const CREAM = getComputedStyle(document.documentElement).getPropertyValue(
	'--cream'
);
const menuToggle = document.querySelector('.menu-toggle');

function preventScroll() {
	gsap.set('body', {
		overflow: 'hidden',
	});
}

function enableScroll() {
	gsap.set('body', {
		overflow: 'auto',
	});
}

// leave the /news link hidden for now
const nonNewsNavLinkQuery = 'nav a:not(.news)';

let navClosed = true;
function openMobileNav() {
	gsap.set('nav', {
		xPercent: 100,
		opacity: 1,
	});
	gsap.set(nonNewsNavLinkQuery, {
		display: 'block',
	});
	preventScroll();
	gsap.to('.menu-toggle__line--1', {
		scale: 0.9,
		duration: 0.2,
		rotate: 45,
		width: '100%',
		y: 9,
		x: -12,
	});
	gsap.to('.menu-toggle__line--2', {
		scale: 0.9,
		duration: 0.2,
		rotate: -45,
		width: '100%',
		x: -4,
	});
	gsap.to('.menu-toggle__line--3', {
		scale: 0.9,
		duration: 0.2,
		rotate: -45,
		width: '100%',
		y: -10,
		x: -4,
	});
	navClosed = false;
}

function closeMobileNav() {
	enableScroll();
	gsap.set('nav', {
		xPercent: 0,
		opacity: 0, //defaults to opacity 0
	});
	gsap.set(nonNewsNavLinkQuery, {
		display: 'none',
	});
	gsap.to('.menu-toggle__line--1', {
		scale: 1,
		duration: 0.2,
		rotate: 0,
		width: '60%',
		y: 0,
		x: 0,
	});
	gsap.to('.menu-toggle__line--2', {
		scale: 1,
		duration: 0.2,
		rotate: 0,
		width: '80%',
		y: 0,
		x: 0,
	});
	gsap.to('.menu-toggle__line--3', {
		scale: 1,
		duration: 0.2,
		rotate: 0,
		width: '60%',
		y: 0,
		x: 0,
	});
	navClosed = true;
}

function largerScreenNav() {
	gsap.set('header', {
		position: 'fixed',
		top: 0,
		width: '100%',
		height: '2rem',
		zIndex: 100,
	});
	gsap.set('nav', {
		position: 'fixed',
		gridTemplateRows: 'none',
		/* MAKE ONLY 5 SLOTS FOR NOW WHILE NEWS IS HIDDEN///////////////////////////////////////////// */
		gridTemplateColumns: 'repeat(5, 1fr)',
		alignItems: 'center',
		top: 0,
		left: '40vw',
		width: 'calc(60vw - 3.5rem)',
		height: '2rem',
		opacity: 1,
		padding: 0,
	});
	gsap.set(nonNewsNavLinkQuery, {
		display: 'block',
	});
}

function smallerScreenNav() {
	gsap.set('header', {
		position: 'fixed',
		top: 0,
		width: 0,
		height: 0,
		zIndex: 100,
	});
	gsap.set('nav', {
		position: 'fixed',
		display: 'grid',
		gridTemplateColumns: 'none',
		/* MAKE ONLY 5 SLOTS FOR NOW WHILE NEWS IS HIDDEN///////////////////////////////////////////// */
		gridTemplateRows: 'repeat(5, 1fr)',
		justifyItems: 'center',
		alignItems: 'center',
		width: '100vw',
		height: '100vh',
		textAlign: 'center',
		left: '-100vw',
		top: 0,
		opacity: 0,
		padding: '0 0 5rem'
	});
}

function hideMenuToggle() {
	menuToggle.disabled = true;
	gsap
		.timeline()
		.to('.menu-toggle', {
			duration: 0.2,
			opacity: 0,
		})
		.to('.menu-toggle', {
			duration: 0,
			pointerEvents: 'none',
			x: -50,
		});
}

function showMenuToggle() {
	menuToggle.disabled = false;
	gsap
		.timeline()
		.to('.menu-toggle', {
			duration: 0,
			pointerEvents: 'auto',
			x: 0,
		})
		.to('.menu-toggle', {
			duration: 0.2,
			opacity: 1,
		});
}

menuToggle
	.addEventListener('click', () => {
		if (navClosed) {
			openMobileNav();
		} else {
			closeMobileNav();
		}
	});

window.addEventListener('resize', resize);
let windowSize;
function resize() {
	width = document.documentElement.clientWidth || window.innerWidth;
	h1Size = getComputedStyle(document.documentElement).getPropertyValue(
		'--h1-size'
	);
	h2Size = getComputedStyle(document.documentElement).getPropertyValue(
		'--h2-size'
	);
	h3Size = getComputedStyle(document.documentElement).getPropertyValue(
		'--h3-size'
	);
	bodySize = getComputedStyle(document.documentElement).getPropertyValue(
		'--body-size'
	);

	/* Change to larger size */
	if (width >= BREAK_POINT && windowSize !== 'large') {
		hideMenuToggle();
		closeMobileNav();
		largerScreenNav();
		windowSize = 'large';
	} else if (width < BREAK_POINT && windowSize !== 'small') {
		/* Change to smaller size */
		showMenuToggle();
		smallerScreenNav();
		windowSize = 'small';
		if (navClosed) {
			closeMobileNav()
		} else {
			openMobileNav();
		}
	}
}
resize();

const IS_KEYBOARD_USER_KEY = 'data-is-keyboard-user';

const setIsKeyboardUserInDom = () => {
	document.querySelector('body')?.setAttribute(IS_KEYBOARD_USER_KEY, 'true');
}

try {
	if (sessionStorage.getItem(IS_KEYBOARD_USER_KEY)) {
		setIsKeyboardUserInDom()
	}
	
	window.addEventListener('keydown', (e) => {
		if (e.key === 'Tab') {
			setIsKeyboardUserInDom();
			sessionStorage.setItem(IS_KEYBOARD_USER_KEY, 'true');
		}
	})
} catch (e) {
	console.error('Error marking user as a keyboard user', e);
}